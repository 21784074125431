import React, { useState, useEffect } from 'react';
import './ProductMap.scss';
import Card from '../cards/Card';
import axios from 'axios';
import { API_ENDPOINTS } from '../backended/connectapi';

const ProductMap = () => {
  const [products] = useState([
    {
      id: '311bee2b-3c',
      x: '15%',
      y: '35%',
    },
    {
      id: 'd4249c6e-64',
      x: '65%',
      y: '60%',
    },
  ]);

  const [products2] = useState([
    {
      id: '751b7078-1b',
      x: '10%',
      y: '35%',
    },
    {
      id: '6a45e3f3-49',
      x: '55%',
      y: '55%',
    },
  ]);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [hoveredProduct, setHoveredProduct] = useState(null);
  const [productData, setProductData] = useState([]);
  const [toggle, setToggle] = useState(false); // State to track the toggle

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await axios.get(API_ENDPOINTS.getshowproducts);
        const data = response.data;
        const filteredData = data.filter((product) =>
          (toggle ? products2 : products).some((p) => p.id === product.pro_ID)
        );
        setProductData(filteredData);

        // Set the first product as selected
        if (filteredData.length > 0) {
          setSelectedProduct(filteredData[0]);
        }
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };
    fetchProductData();
  }, [products, products2, toggle]);

  const getProductDetails = (id) => {
    return productData.find((product) => product.pro_ID === id);
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  const handleToggleChange = () => {
    setToggle(!toggle);
  };

  return (
    <div className='map-container'>
      <h1>เลือกตามสไตล์</h1>
      <label htmlFor="filter" className="switch" aria-label="Toggle Filter">
        <input type="checkbox" id="filter" onChange={handleToggleChange} />
        <span>ธีมดำ</span>
        <span>ธีมขาว</span>
      </label>
      <div className="product-map-container">
        <div className="image-container" id='map-black' style={{ display: toggle ? 'none' : 'block' }}>
          <img src="mapsimg/DP_4-1.png" alt="Model" className="model-image" />
          {products.map((product) => (
            <button
              key={product.id}
              className="product-button"
              style={{ left: product.x, top: product.y }}
              onMouseEnter={() => setHoveredProduct(getProductDetails(product.id))}
              onMouseLeave={() => setHoveredProduct(null)}
              onClick={() => handleProductClick(getProductDetails(product.id))}
            ></button>
          ))}
        </div>
        <div className="image-container" id='map-white' style={{ display: toggle ? 'block' : 'none' }}>
          <img src="mapsimg/DP_4-2.png" alt="Model" className="model-image" />
          {products2.map((product) => (
            <button
              key={product.id}
              className="product-button"
              style={{ left: product.x, top: product.y }}
              onMouseEnter={() => setHoveredProduct(getProductDetails(product.id))}
              onMouseLeave={() => setHoveredProduct(null)}
              onClick={() => handleProductClick(getProductDetails(product.id))}
            ></button>
          ))}
        </div>
        <div className="product-card-container">
          {selectedProduct && (
            <Card
              id={selectedProduct.pro_ID}
              title={selectedProduct.pro_name}
              colors={selectedProduct.colors}
              price={
                selectedProduct.provariation_saleprice && selectedProduct.provariation_saleprice > 0
                  ? selectedProduct.provariation_saleprice
                  : selectedProduct.provariation_price
              }
              images={selectedProduct.images}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductMap;