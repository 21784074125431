import React from 'react';
import './selection.scss';
import FilterLink from '../presets/FilterLink';

const Selection = () => {
    return (
        <div className='selection-card-container'>
            <FilterLink
                clearFilters={true}
                to={`/productslist?category=ผู้หญิง?title=เสื้อยืด&cateid=2`}

            >
                <div className='selection-card'>
                    <img src='selectionimages/DP_1-1.png' />
                    {/* <p className='card-text'>เสื้อผู้หญิง</p> */}
                </div>
            </FilterLink>
            <FilterLink
                clearFilters={true}
                to={`/productslist?category=ผู้ชาย?title=เสื้อยืด&cateid=1`}

            >
                <div className='selection-card'>
                    <img src='selectionimages/DP_1-2.png' />
                    {/* <p className='card-text'>เสื้อผู้ชาย</p> */}
                </div>
            </FilterLink>
        </div>
    );
};

export default Selection;