import React from 'react';
import '../css/AboutUs.scss';

const AboutUs = () => {
  return (
    <>
      <div className="responsive-container-block bigContainer">
        <div className="responsive-container-block Container">
          <img className="mainImg" src="layoutwm.jpg" />
          <div className="allText aboveText">
            <p className="text-blk headingText">
              เกี่ยวกับเรา
            </p>
            <p className="text-blk subHeadingText">
              Easy to Wear, No Hassle <br />"ใส่สบาย ไม่ต้องคิดเยอะ"
            </p>
            <p className="text-blk description">
              ความเรียบง่าย คือ สิ่งที่เราต่างมองหา เสื้อยืดของเราออกแบบมาเพื่อความสบายและความคล่องตัว ไม่ว่าจะทำงาน พักผ่อน หรือออกไปพบปะเพื่อนฝูง เสื้อยืด
              นี้จะเป็นส่วนหนึ่งในทุกกิจกรรม ด้วยดีไซน์มินิมอลที่เข้าได้กับทุกลุค เนื้อผ้านุ่มใส่สบาย และการตัดเย็บที่พิถีพิถัน ทำให้คุณพร้อมสำหรับทุกวัน




            </p>
          </div>
        </div>
        <div className="responsive-container-block Container bottomContainer">
          <img className="mainImg" src="layoutw.jpg" />
          <div className="allText bottomText">
            <p className="text-blk description">
              เราให้ความสำคัญกับการผลิตที่ยั่งยืน วัสดุทุกชิ้นถูกเลือกมาอย่างใส่ใจ เพื่อให้คุณรู้สึกดีทั้งกับตัวเองและโลกใบนี้ เสื้อยืดของเราไม่ได้เป็นเพียงแค่เสื้อผ้า แต่
              เป็นสัญลักษณ์ของการใช้ชีวิตอย่างเรียบง่าย สบาย และยั่งยืน
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
