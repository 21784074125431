import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import '../css/ProductList.scss';
import Card from '../cards/Card';
import Dropdown from '../presets/Dropdown';
import { API_ENDPOINTS } from '../backended/connectapi';
import { useLocation } from 'react-router-dom';

const ProductList = () => {
    const [products, setProducts] = useState([]);
    const [displayedProducts, setDisplayedProducts] = useState([]); // Products displayed on the page
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sortOption, setSortOption] = useState(0);
    const [filters, setFilters] = useState({
        categories: [],
        styles: [],
        colors: [],
    });


    const [limit, setLimit] = useState(10); // Initial limit of 10 products
    const [hasMore, setHasMore] = useState(true); // Whether there are more products to load
    const location = useLocation();

    const geturlcatestyle = new URLSearchParams(location.search);
    const cateid = geturlcatestyle.get('cateid');

    const [category, setcategory] = useState([]);

    useEffect(() => {
        const fetchcategory = async () => {
            try {
                const response = await axios.get(API_ENDPOINTS.getCategorysID(cateid));
                const data = response.data;
                setcategory(data);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };
        fetchcategory();
    }, []);


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);

        const stylesParam = queryParams.get('styles');
        const savedStyles = stylesParam ? JSON.parse(stylesParam) : [];

        setFilters({
            ...filters,
            styles: savedStyles,
        });
    }, [location.search]);

    // Fetch products from the API
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(API_ENDPOINTS.getshowproducts);
                const data = response.data;
                if (Array.isArray(data)) {
                    if (cateid !== null) {
                        const filteredData = data.filter((product) =>
                            product.variations.some((variation) => variation.procata_id === cateid)
                        );
                        setProducts(filteredData);
                    } else {
                        setProducts(data);
                    }
                    setLoading(false);
                } else if (data.error) {
                    setError(data.error);
                    setLoading(false);
                } else {
                    setError('Unexpected API response');
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error fetching products:', error);
                setError('Error fetching products');
                setLoading(false);
            }
        };

        fetchProducts();
    }, [cateid]);

    // Memoize the handle functions
    const handleSortChange = useCallback((index) => {
        setSortOption(index);
    }, []);

    const handleFilterChange = useCallback((newFilters) => {
        setFilters(newFilters);
        setLimit(10); // Reset limit when filters are applied
    }, []);

    // Filter, sort, and paginate products
    useEffect(() => {
        let filteredProducts = [...products];

        // Apply category filters
        if (filters.categories.length > 0) {
            filteredProducts = filteredProducts.filter((product) =>
                filters.categories.some(
                    (category) => product.proty_name === category.proty_name
                )
            );
        }

        // Apply style filters
        if (filters.styles.length > 0) {
            filteredProducts = filteredProducts.filter((product) =>
                filters.styles.some(
                    (style) => product.prosty_name === style.prosty_name
                )
            );
        }

        // Apply color filters
        if (filters.colors.length > 0) {
            filteredProducts = filteredProducts.filter((product) =>
                product.colors.some((productColor) =>
                    filters.colors.some((selectedColor) => productColor.name === selectedColor.procol_name)
                )
            );
        }
        // Sort products based on sort option
        switch (sortOption) {
            case 1: // Sort by price low to high
                filteredProducts.sort((a, b) => {
                    const priceA =
                        parseFloat(a.provariation_saleprice) > 0
                            ? parseFloat(a.provariation_saleprice)
                            : parseFloat(a.provariation_price);
                    const priceB =
                        parseFloat(b.provariation_saleprice) > 0
                            ? parseFloat(b.provariation_saleprice)
                            : parseFloat(b.provariation_price);
                    return priceA - priceB;
                });
                break;
            case 2: // Sort by price high to low
                filteredProducts.sort((a, b) => {
                    const priceA =
                        parseFloat(a.provariation_saleprice) > 0
                            ? parseFloat(a.provariation_saleprice)
                            : parseFloat(a.provariation_price);
                    const priceB =
                        parseFloat(b.provariation_saleprice) > 0
                            ? parseFloat(b.provariation_saleprice)
                            : parseFloat(b.provariation_price);
                    return priceB - priceA;
                });
                break;
            default: // Sort by newest
                filteredProducts.sort(
                    (a, b) => new Date(b.time_stemp) - new Date(a.time_stemp)
                );
                break;
        }

        // Paginate the filtered products (load more products)
        const currentDisplay = filteredProducts.slice(0, limit);
        setDisplayedProducts(currentDisplay);

        // Check if there are more products to load
        setHasMore(currentDisplay.length < filteredProducts.length);
    }, [products, filters, sortOption, limit]);

    // Function to load more products
    const loadMoreProducts = () => {
        setLimit(limit + 10); // Increment the limit by 10
    };

    return (
        <>
            <div className="dropdown-container">
                <h1 className="showingname">
                    {cateid === null ? 'สินค้าทั้งหมด' : `สินค้า${category[0]?.procata_name}`}
                </h1>
                <div className="dropdown-component">
                    <Dropdown
                        onSortChange={handleSortChange}
                        onFilterChange={handleFilterChange}
                    />
                </div>
            </div>
            <div className="products-page">
                {/* Product Grid */}
                <div className="product-grid">
                    {loading ? (
                        <div>Loading products...</div>
                    ) : error ? (
                        <div>Error: {error}</div>
                    ) : (
                        displayedProducts.map((product) => (
                            <Card
                                id={product.pro_ID}
                                key={product.pro_ID}
                                title={product.pro_name}
                                price={
                                    product.provariation_saleprice &&
                                        product.provariation_saleprice > 0
                                        ? product.provariation_saleprice
                                        : product.provariation_price
                                }
                                images={product.images} // Pass the images array here
                                colors={product.colors}

                                isLoading={false}
                                layout="product-list"
                            />
                        ))
                    )}
                </div>

            </div>
            {/* Load More Button */}
            {hasMore && (
                <div className='load-more-container'>
                    <a onClick={loadMoreProducts} className="load-more-btn">
                        ดูเพิ่มเติม
                    </a>
                </div>
            )}
        </>
    );
};

export default ProductList;
