import React from 'react';
import './Footer.scss'; // You'll need to create this file for styling
import { Link, useLocation } from 'react-router-dom'; // Import useLocation
import Facebookjsx from '../icons/facebookjsx';
import Shopeejsx from '../icons/shopeejsx';
import Lazadajsx from '../icons/lazadajsx';
import Tiktokjsx from '../icons/tiktokjsx';

const Footer = () => {
  const location = useLocation(); // Get the current location
  const hiddenRoutes = ['/LoginPage', '/dashboard'];
  if (hiddenRoutes.includes(location.pathname)) {
    return null;
  }
  return (
    <footer className="footer">
      <div className="footer-top">
        <a href="#top" className="back-to-top">กลับสู่ด้านบน ↑</a>
      </div>

      <div className="footer-columns">
        <div className="footer-column">
          <Link to="/productslist"><h3>เกี่ยวกับเรา</h3></Link>
          <h3>ติดต่อเรา</h3>
          <p>
            21 ซอยพัฒนาการ 13 ถนนพัฒนาการ
          </p>
          <div className="contact-info">
            <p>แขวงสวนหลวง เขตสวนหลวง กรุงเทพ 10250</p>
            <p>เบอร์โทร 065-925-8320</p>
          </div>
        </div>

        <div className="footer-column">
          <h3>Shop</h3>
          <ul>
            <Link to="/productslist">ผลิตภัณฑ์ใหม่</Link>
          </ul>
        </div>
        <div className="footer-column">
          <h3>ติดตามข่าวสาร</h3>
          <p>ส่งอีเมลของคุณ เพื่อรับข่าวใหม่ๆ ของเรา</p>
          <form className="subscribe-form">
            <input type="email" placeholder="Enter your email" />
            <button type="submit">Subscribe</button>
          </form>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="social-links">
          <a><Facebookjsx className="social-icons"/></a>
          <a><Shopeejsx className="social-icons"/></a>
          <a><Lazadajsx className="social-icons"/></a>
          <a><Tiktokjsx className="social-icons"/></a>
        </div>
        <p>© 2024 DOUBLEPLALOMA • All rights reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
