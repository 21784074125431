import * as React from "react";
const SVGComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.03 27.62" {...props} fill="currentColor">
    <defs>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="katman_1" data-name="katman 1">
        <polygon points="12.9 11.61 13.67 12.02 14.02 11.61 12.9 11.61" />
        <path d="M31.19,19.1c-3.25,2-9.28,5.78-12.12,7.52C21.91,24.9,27.94,21.12,31.19,19.1Z" />
        <path d="M16.35,27.42C14.55,26.4,1.23,18,.72,17.77a1.25,1.25,0,0,1-.53-.46,1.34,1.34,0,0,0,.52.48c.5.25,13.83,8.61,15.63,9.65a1.34,1.34,0,0,0,.62.17h0A1.65,1.65,0,0,1,16.35,27.42Z" />
        <path d="M34,5s0,.08,0,.12h0S34,5,34,5Z" />
        <path d="M18.6,11.51a1.52,1.52,0,0,0-1.51,1.63,1.79,1.79,0,0,0,.09.57l2.7-1.49A1.43,1.43,0,0,0,18.6,11.51Z" />
        <path d="M33.92,4.59A.86.86,0,0,1,34,4.8.86.86,0,0,0,33.92,4.59Z" />
        <path d="M11.37,10.58h4.1v.91l-.87,1,1.34.73s0-.08,0-.12a2.55,2.55,0,0,1,2.47-2.66,2,2,0,0,1,1.64.79v-.69h1.12v.94L26,8.86V8.33h1l6.92-3.8A1.31,1.31,0,0,0,33.43,4C33.2,3.87,27.85.59,27.07.16a1,1,0,0,0-1,0c-.07,0-5.84,3.67-6.74,4.14A4.69,4.69,0,0,1,17,4.85,4.71,4.71,0,0,1,15,4.41C14,4.06,8,.12,8,.12A1,1,0,0,0,7.49,0,1,1,0,0,0,7,.14C6.29.52,2,3.16.69,4L.59,4a1.32,1.32,0,0,0-.49.65l11.27,6.12Z" />
        <path d="M34,4.8a.86.86,0,0,0-.07-.21s0,0,0-.06h0L27,8.33h.17V15.7H26V15a2,2,0,0,1-1.64.79,2.56,2.56,0,0,1-2.47-2.67,2.55,2.55,0,0,1,2.47-2.66,2,2,0,0,1,1.64.79V8.86l-4.86,2.66V15.7H20V15a2.07,2.07,0,0,1-1.64.79,2.33,2.33,0,0,1-1.44-.5V27.6H17a1.39,1.39,0,0,0,.69-.18l1.35-.82c2.84-1.74,8.87-5.51,12.12-7.52,1.21-.75,2-1.25,2.15-1.31a1.21,1.21,0,0,0,.69-1h0V5.1S34,5,34,5A1.13,1.13,0,0,0,34,4.8Zm-6.1,8.34a2.55,2.55,0,0,1,2.47-2.66,2,2,0,0,1,1.64.79v-.69h1.12V15.7H32V15a2,2,0,0,1-1.64.79A2.55,2.55,0,0,1,27.89,13.14Z" />
        <path d="M24.59,11.51a1.52,1.52,0,0,0-1.52,1.63,1.52,1.52,0,1,0,3,0A1.52,1.52,0,0,0,24.59,11.51Z" />
        <path d="M17.18,13.71a1.45,1.45,0,0,0,1.42,1.07,1.53,1.53,0,0,0,1.52-1.64,1.85,1.85,0,0,0-.24-.92Z" />
        <path d="M30.57,11.51a1.52,1.52,0,0,0-1.52,1.63,1.52,1.52,0,1,0,3,0A1.52,1.52,0,0,0,30.57,11.51Z" />
        <path
          className="cls-1"
          d="M0,5.1A1.32,1.32,0,0,1,.1,4.66h0A1.26,1.26,0,0,0,0,5.08V16.8a1.13,1.13,0,0,0,.19.51A1.19,1.19,0,0,1,0,16.82Z"
        />
        <path d="M7.9,14.78a1.52,1.52,0,0,0,1.51-1.64,1.52,1.52,0,1,0-3,0A1.53,1.53,0,0,0,7.9,14.78Z" />
        <path
          className="cls-1"
          d="M7.9,14.78a1.52,1.52,0,0,0,1.51-1.64,1.52,1.52,0,1,0-3,0A1.53,1.53,0,0,0,7.9,14.78Z"
        />
        <path d="M.72,17.77c.51.27,13.83,8.63,15.63,9.65a1.65,1.65,0,0,0,.61.18V15.31a2.7,2.7,0,0,1-1-2.05l-1.34-.73-1.8,2.15h2.73v1h-4.2v-.88L13.67,12l-.77-.41H11.37v-.83L.1,4.66A1.32,1.32,0,0,0,0,5.1V16.82a1.19,1.19,0,0,0,.17.49A1.25,1.25,0,0,0,.72,17.77Zm7-7.29a2,2,0,0,1,1.64.79v-.69h1.13V15.7H9.33V15a2.05,2.05,0,0,1-1.64.79,2.56,2.56,0,0,1-2.47-2.67A2.55,2.55,0,0,1,7.69,10.48ZM2.81,8.74v5.81H4.9v1.14H1.61V8.74Z" />
        <path
          className="cls-1"
          d="M.72,17.77c.51.27,13.83,8.63,15.63,9.65a1.65,1.65,0,0,0,.61.18V15.31a2.7,2.7,0,0,1-1-2.05l-1.34-.73-1.8,2.15h2.73v1h-4.2v-.88L13.67,12l-.77-.41H11.37v-.83L.1,4.66A1.32,1.32,0,0,0,0,5.1V16.82a1.19,1.19,0,0,0,.17.49A1.25,1.25,0,0,0,.72,17.77Zm7-7.29a2,2,0,0,1,1.64.79v-.69h1.13V15.7H9.33V15a2.05,2.05,0,0,1-1.64.79,2.56,2.56,0,0,1-2.47-2.67A2.55,2.55,0,0,1,7.69,10.48ZM2.81,8.74v5.81H4.9v1.14H1.61V8.74Z"
        />
      </g>
    </g>
  </svg>
);
export default SVGComponent;
