import React from 'react';
import './AdSection.scss';

const AdSection = () => {
  return (
    <section className="ad-section">
      <div className="ad-content" style={{ backgroundImage: `url('homepageimg/DP_1-0/DP_1-3.png')` }}>
        <div className="ad-text">
          <h2>Double Plaloma</h2>
          <p>สินค้าใหม่แนะนำ!</p>
        </div>
      </div>
    </section>
  );
};

export default AdSection;
